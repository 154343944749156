/// This file should define styles for overriding all elements in applications,
/// root variables and style classes that do not already have their own files.
/// Also, you can import other style files.

@use 'styles/variables';
@use 'bootstrap/scss/bootstrap' with (
  $grid-breakpoints: variables.$grid-breakpoints,
  $container-max-widths: variables.$container-max-widths
);
@use 'styles/mixin';
@use 'styles/fonts';
@use 'styles/type';
@use 'styles/accordion';
@use 'styles/pagination';
@use 'styles/popover';
@use 'styles/utility';
@use 'styles/custom-date-picker.scss';
@use 'styles/chrome-color-picker.scss';
@use 'styles/input';
@import 'styles/custom';
@import 'styles/dropdown';
@import 'styles/table';
@import 'styles/modal';
@import 'styles/button';
@import 'assets/scss/custom-p-table';
@import 'assets/scss/custom-ng-select';
@import 'assets/scss/custom-container';
@import 'assets/scss/custom-switch-checkbox';
@import 'assets/scss/sweetalert.scss';

:root {
  --primary-red: #70161a;
  --head-table: #bbb7a3;
  --background: #e8e8e8;
  --primary-border: #8b6a5a;
  --dark-green: #3b7575;
  --dark: #343a40;
  --blue: #52687d;
  --royal-blue: #{variables.$royal-blue};
  --yale-blue: #{variables.$yale_blue};
  font-size: variables.$default-mobile-font-size;

  @include bootstrap.media-breakpoint-up('lg', variables.$grid-breakpoints) {
    font-size: variables.$default-font-size;
  }
}

/**
 * Fix fonts that render as bold in Firefox
 *
 * Put this near the top of your style.css
 * Before any overriding styles
 */

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  font-weight: 400;
}

/**
 * Firefox specific rule
 */

@-moz-document url-prefix() {
  body {
    font-weight: normal !important;
  }
}

html,
body {
  min-height: 100%;
  overflow-y: hidden;
  margin: 0;
  max-width: 100vw;
  background: #e8e8e8;
  color: #000;
  font-family: 'Bai Jamjuree', 'Prompt', sans-serif;

  * {
    text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
    outline: none;
    -webkit-animation: fadein 400ms;
    /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 400ms;
    /* Firefox < 16 */
    -ms-animation: fadein 400ms;
    /* Internet Explorer */
    -o-animation: fadein 400ms;
    /* Opera < 12.1 */
    animation: fadein 400ms;
    -webkit-font-smoothing: antialiased;
  }

  font-weight: normal;
}

.card {
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-radius: 0.25rem;
  box-shadow: 0px 3px 6px #0000001a;
  transition: 0.2s all;
  cursor: pointer;
  position: relative;
  padding: 0;
  border: none !important;
}

.container-page {
  %responsive-gutter-variables {
    --bs-gutter-x: #{calc(variables.$default-font-size * 2)};
    --bs-gutter-y: #{calc(variables.$default-font-size * 3)};

    @include bootstrap.media-breakpoint-up('lg', variables.$grid-breakpoints) {
      --bs-gutter-x: 4rem;
      --bs-gutter-y: 3rem;
    }
  }

  > .container {
    @extend %responsive-gutter-variables;

    display: flex;
    flex-direction: column;
    padding: 0;

    > *:not(router-outlet, ngb-modal-window, ngb-modal-backdrop) {
      @extend %responsive-gutter-variables;

      display: inline-block;
      flex: 1 1 auto;
      margin-bottom: calc(var(--bs-gutter-y) * 0.5);
      padding-top: calc(var(--bs-gutter-y) * 0.5);
      padding-right: calc(var(--bs-gutter-x) * 0.5);
      padding-left: calc(var(--bs-gutter-x) * 0.5);
      width: 100%;
    }
  }
}

.p-component {
  --font-family: 'Bai Jamjuree', 'Prompt', sans-serif;
}

.profile-image {
  border-radius: 50%;
  cursor: default;
}

.bordered {
  border: solid #ccc 1px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  -webkit-box-shadow: 0 1px 1px #ccc;
  -moz-box-shadow: 0 1px 1px #ccc;
  box-shadow: 0 1px 1px #ccc;
}

.notification {
  color: white;
  text-decoration: none;
}

.notification .badge {
  margin-left: 5px;
  border-radius: 50%;
  background: #b1b1b1;
  color: white;
}

.container {
  margin-top: 15px;
  // max-height: calc(100vh - 40px);
  // overflow: scroll;
}

.tab {
  padding: 0.375rem 0.5rem;
  color: #b1b1b1;
  border: none;
  background-color: transparent;
  position: relative;
  top: 2px;
  width: 118px;
  font-size: 16px;
}

.color {
  &-inherit {
    color: inherit;

    &.inherit-placeholder {
      &::placeholder {
        color: inherit;
      }
    }
  }
}

.line {
  border-bottom: 1px solid #e7e7e7;
}

.shadow {
  box-shadow: 0px 3px 6px #0000001a !important;
}

.is-invalid {
  input {
    border: 1px solid red;
  }
}

.bg-none {
  background-color: #fafafa !important;
  box-shadow: none !important;
}

.disable {
  pointer-events: none;
  opacity: 0.5;
  background: darkgray;
  border: none;
}

.button:focus,
button:focus,
.button:hover,
button:hover {
  outline: none !important;
}

input,
select {
  // border: none;
  border: 1px solid #e0e0e0;
}

.event-none {
  pointer-events: none;
}

.content-frame {
  background: #ffffff;
  padding: 1rem;
  border-radius: 5px;
  box-shadow: 1px 3px 6px #0000001a;
  height: calc(100vh - 100px);
  overflow: scroll;
  overflow-x: hidden;

  &.dark-theme {
    border-radius: 0;
    background: #f8f8f8;

    p,
    h5 {
      color: #1f1061;
    }
  }

  &:not(.not-apply-child) {
    label {
      color: #8395a7;
      line-height: 1rem;
    }

    p {
      color: variables.$royal-blue;
    }

    h5 {
      color: variables.$royal-blue;
    }
  }
}

.text-name-noti {
  color: #323232 !important;
  font-size: 11.5pt;
  font-weight: bold;
}

.tag {
  border-radius: 0;
  color: #f2f2f2;
  font-weight: bold;
  text-align: center;
  margin: auto;
  padding: 3px 10px;
  display: inline-block;
  text-transform: uppercase;

  &.blue {
    background: #43608a;
  }

  &.yellow {
    background: #edc468;
  }

  &.red {
    background: #e46a76;
  }

  &.green {
    background: #1abc9c;
  }

  &.grey {
    // background: #A7A7A7;
    background: #40667bb3;
  }

  &.light {
    color: #4f4f4f;

    &.blue {
      background: #a8caf7;
    }

    &.yellow {
      background: #fee9a9;
    }

    &.red {
      background: #feaeae;
    }

    &.green {
      background: #caf2da;
    }

    &.grey {
      background: #dedede;
    }
  }
}

.nav-link {
  background: #ededed;
  border-radius: 0;
  margin: 2px;
  text-align: center !important;
  color: #6b6363;
  transition: 300ms all;

  &.text-green {
    background: #03a9f3;
    border-radius: 0;
    color: #ffffff !important;
  }

  &:hover {
    box-shadow: 0 4px 6px 0 hsla(0, 0%, 0%, 0.2);
  }
}

.size-svg {
  svg {
    width: 16px;
  }
}

@supports (-moz-appearance: none) {
  * {
    scrollbar-width: thin;
    scrollbar-color: #b1b1b1 rgba(132, 132, 132, 0.2);
  }
}

.ng2-pdf-viewer-container {
  display: flex !important;
  justify-content: center;
}

:host ::ng-deep {
  .swal2-title {
    font-size: 1.5rem;
  }
}

::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: rgba(60, 60, 60, 0.4);
  -webkit-border-radius: 2.5px;
  -webkit-box-shadow: none;
}

::-webkit-scrollbar-track,
::-webkit-scrollbar {
  background: transparent !important;
}

.custom-box {
  input[type='text'],
  input[type='password'],
  input[type='number'],
  input[type='email'],
  input[name='datepicker'],
  input[name='number'],
  textarea[type='text'],
  .ng-select-container {
    @include mixin.reset-input;
    @include mixin.style-input;
  }

  input[type='text']:disabled,
  input[type='password']:disabled,
  input[type='number']:disabled,
  input[type='email']:disabled,
  input[name='datepicker']:disabled,
  .ng-select-container:disabled {
    @include mixin.reset-input;
    @include mixin.style-input;
    @include mixin.disable-input-bg;
  }

  .ng-select-focused {
    .ng-select-container {
      box-shadow: none !important;
      border-color: lightgray !important;
    }
  }

  .ng-select.ng-select-single .ng-select-container {
    @include mixin.style-input;
  }

  .ng-select.ng-select-multiple .ng-select-container {
    @include mixin.style-input;
  }

  .ng-select.ng-select-disabled > .ng-select-container {
    @include mixin.style-input;
    @include mixin.disable-input-bg;
  }

  .ng-select.ng-select-disabled .ng-arrow-wrapper {
    display: none;
  }

  .ng-select .ng-select-container .ng-value-container .ng-input > input {
    height: auto;
    background-color: unset !important;
  }
}

.custom-drop-file {
  background: #e8f1fc;
  border: 1px dashed #2d5b8f;
  box-sizing: border-box;
  border-radius: 4px;
}

.p-btn {
  padding: 0.5rem;
}

.content-frame label {
  color: #4d4d4d;
}

.custom-content-frame label {
  color: #4d4d4d;
}

.remain {
  font-size: 12px;
  color: variables.$gray-neutrals;
}

// Change toggle switch color.
.custom-control-input {
  animation: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}

.custom-control-input:focus ~ .custom-control-label::before {
  border-color: #57b99d !important;
  box-shadow: 0 0 0 0.2rem rgba(87, 185, 157, 0.25) !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #57b99d !important;
  background-color: #57b99d !important;
}

.custom-control-input:active ~ .custom-control-label::before {
  background-color: #57b99d !important;
  border-color: #57b99d !important;
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #57b99d !important;
}

.custom-control-input-green:not(:disabled):active ~ .custom-control-label::before {
  background-color: #57b99d !important;
  border-color: #57b99d !important;
}

.enable-status-banner {
  background-color: #e8f1fc;
  color: #2d5b8f;
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  border-radius: 1rem;

  .dot {
    height: 7px;
    width: 7px;
    background-color: #57b99d;
    border-radius: 50%;
    display: inline-block;
  }

  &.disabled {
    background-color: #f6f6f6;
    color: #919191;

    .dot {
      background-color: #b1b1b1;
    }
  }
}

pdf-viewer .ng2-pdf-viewer-container .pdfViewer .page .annotationLayer {
  // not show all popup when hover each element in pdf.
  pointer-events: none;
}

.custom-input-height {
  height: 36px;
}

.fa-chevron-left:before {
  font-weight: 500;
}

.fa-chevron-right:before {
  font-weight: 500;
}

.fa-times:before {
  font-weight: 350;
}

.icon-btn-svg {
  height: 20px;
  width: 20px;
}

.icon-x {
  float: right;
  margin-top: -28px;
  position: relative;
  cursor: pointer;
  z-index: 1;
  margin-right: 30px;
}

.height-modal-20vh {
  height: 20vh;
}

pdf-viewer {
  .ng2-pdf-viewer-container {
    overflow: hidden;
  }
}

.pdf-viewer-viewer-window {
  pdf-viewer {
    .ng2-pdf-viewer-container {
      overflow: inherit;
      overflow-x: auto;
    }
  }
}

.embed-attachments .popover-body {
  font-size: 10px;
}

.font-style-italic {
  font-style: italic;
}

.modal-under-navbar {
  z-index: 90 !important;
}

.text-charcoal {
  color: #333333 !important;
}

.progress {
  border-radius: 1.25rem;
  margin-top: 0.75rem;
  margin-bottom: 1.25rem;
}

.otp-input {
  border-radius: variables.$border-radius !important;
  background: variables.$white-pure !important;
}

.each_input:focus {
  border-radius: variables.$border-radius !important;
}

label {
  margin-bottom: 0.5rem !important;
}

.form-group {
  margin-top: 1rem;
}

a {
  color: #0056b3;
  text-decoration: none;

  &:hover {
    color: #0056b3;
    text-decoration: underline;
  }
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid lightslategrey;
}

.big-checkbox {
  width: 1.25rem;
  height: 1.25rem;
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

.badge-std {
  --bs-badge-font-size: 0.857em;
  --bs-badge-padding-y: 0.375em;
  --bs-badge-padding-x: 0.675em;
  line-height: 1rem;
}

.grid-view {
  align-items: flex-start;
  animation: fade-in 0.5s forwards;
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(256px, 1fr));
  margin: 0;

  @media screen and (max-width: 1024px) {
    grid-template-columns: repeat(auto-fill, minmax(185px, 1fr));
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(155px, 1fr));
  }
}

.tooltip-width-85px .tooltip-inner {
  width: 85px !important;
}

.tooltip-notification {
  .tooltip-inner {
    max-width: fit-content !important;
    z-index: 99 !important;

    @include bootstrap.media-breakpoint-down('xl', variables.$grid-breakpoints) {
      white-space: pre-wrap !important;
      max-width: 35vw !important;
    }

    @include bootstrap.media-breakpoint-down('sm', variables.$grid-breakpoints) {
      max-width: 60vw !important;
    }
  }
}

.bs-datepicker-container {
  padding: 39px 37px;

  .bs-datepicker-head {
    background-color: transparent;

    button {
      font-size: 0.875rem;
      color: #212529;

      .previous,
      .next {
        font-weight: 200;
      }

      &:hover {
        background-color: hsl(var(--std-color-1));
        color: #ffffff;
        border-radius: 30px;
      }
    }
  }
}

.bs-datepicker-body table td {
  font-size: 0.875rem;
  color: #212529;
}

.bs-datepicker-body span:hover {
  background-color: hsl(var(--std-color-1)) !important;
  color: #ffffff;
  border-radius: 30px;
}

.bs-datepicker-body table td span.selected {
  background-color: hsl(var(--std-color-3)) !important;
}